const headerGraphql = `{
  header {
    logo
    terms-and-contitions
    shop_links_h {
      link
      text
      highlight
      highlight_color
      highlight_background
      countdown
      ab_test_version
      sub_menu {
        ... on sub_menu {
          links {
            link
            text
            highlight
            highlight_color
            highlight_background
          }
        }
      }
    }
    menu_h {
      link
      text
      highlight
      highlight_color
      highlight_background
      countdown
      ab_test_version
      sub_menu {
        ... on sub_menu {
          links {
            link
            text
            highlight
            highlight_color
            highlight_background
          }
        }
      }
    }
    shop_links_f {
      link
      text
      highlight
      highlight_color
      highlight_background
      countdown
      ab_test_version
      sub_menu {
        ... on sub_menu {
          links {
            link
            text
            highlight
            highlight_color
            highlight_background
          }
        }
      }
    }
    menu_f {
      link
      text
      highlight
      highlight_color
      highlight_background
      countdown
      ab_test_version
      sub_menu {
        ... on sub_menu {
          links {
            link
            text
            highlight
            highlight_color
            highlight_background
          }
        }
      }
    }
  }
}`

export default headerGraphql
