<template>
  <div>
    <app-banner v-if="proposeApp" />

    <default-banner
      v-else-if="showBanner"
      id="banner"
      :text="banner.text"
      :text-mobile="banner.mobile_text"
      :tooltip="banner.tooltip"
      :cookie-key="banner.cookie_key"
      :banner-link="banner.banner_link"
      :expiration-days="banner.expiration_days"
    />

    <div class="body__inner" :class="bodyClass">
      <header-default
        v-if="header"
        :header="header"
        :skeleton="loadingHeader"
        :class="{ 'menu-open': isNavOpen, 'cart-open': isCartOpen }"
        @open="openMenu"
        @switch_context="switchContext"
      />

      <Nuxt id="main" />

      <footer-default
        v-if="footer"
        :footer="footer"
        :highlighted-product-from-payload="highlightedProduct"
        :next-highlighted-product-from-payload="nextHighlightedProduct"
        :skeleton="loadingFooter"
      />
      <!-- <cookie-banner /> -->

      <cart-menu
        id="cartMenu"
        ref="cartMenu"
        :class="{
          'side-menu--open': isCartOpen,
        }"
        :cgv-link="cgvLink"
      />

      <nav-mobile
        v-if="header"
        :header="header"
        :class="{ 'side-menu--open': isNavOpen }"
        @close="toggleMenu"
      />
    </div>

    <force-connect-modal
      v-if="forceConnect"
      :email="forceConnectEmail"
      :account-status="forceConnectStatus"
      @close="forceConnect = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import HeaderDefault from '@/components/header/HeaderDefault'
import FooterDefault from '@/components/footer/FooterDefault'
import CartMenu from '@/components/cart/CartMenu'
import NavMobile from '@/components/nav/NavMobile'

import footerGraphql from '@/components/footer/footerGraphql'

import DeliveryMixin from '@/mixins/deliveryText'

import { LS_CHECKOUT_ID } from '@/const'
import { EVENTS } from '@/const/events'

import services from '@/services'
import headerGraphql from '~/components/header/headerGraphql'

export default {
  name: 'DefaultLayout',
  components: {
    HeaderDefault,
    FooterDefault,
    DefaultBanner: () => ({
      component: import(
        '@/components/banner/DefaultBanner' /* webpackChunkName: 'default_banner' */
      ),
    }),
    // CookieBanner,
    CartMenu,
    NavMobile,
    ForceConnectModal: () => ({
      component: import(
        '@/components/modal/ForceConnect' /* webpackChunkName: 'force_connect_modal' */
      ),
    }),
    AppBanner: () => ({
      component: import(
        '@/components/banner/AppBanner' /* webpackChunkName: 'app_banner' */
      ),
    }),
  },

  mixins: [DeliveryMixin],

  data() {
    return {
      header: null,
      loadingHeader: true,

      footer: null,
      highlightedProduct: null,
      nextHighlightedProduct: null,
      loadingFooter: true,

      banner: null,

      isNavOpen: false,
      isCartOpen: false,

      forceConnect: false,
      forceConnectEmail: '',
      forceConnectStatus: '',

      cgvLink: '',
      notificationAsked: false,

      proposeApp: false,
      gorgiasIsReady: false,

      touch: {
        down: {
          x: null,
          y: null,
        },
        move: {
          x: null,
          y: null,
        },
      },

      mounted: false,
    }
  },
  async fetch() {
    if (
      this.$parent.context &&
      this.$parent.context.payload &&
      this.$parent.context.payload.header
    ) {
      this.header = this.$parent.context.payload.header
      this.loadingHeader = false
    } else {
      const headerDocument = await this.$prismic.client.getSingle('header', {
        graphQuery: headerGraphql,
        lang: this.$i18n.locale,
      })
      this.header = headerDocument
      this.loadingHeader = false
    }

    if (this.header && this.header.data['terms-and-contitions']) {
      this.cgvLink = services.linkResolverService.getPathFromLink(
        this.header.data['terms-and-contitions']
      )
    }

    if (
      this.$parent.context &&
      this.$parent.context.payload &&
      this.$parent.context.payload.footer
    ) {
      this.footer = this.$parent.context.payload.footer.data

      if (this.$parent.context.payload.highlightedProduct) {
        this.highlightedProduct =
          this.$parent.context.payload.highlightedProduct
      }

      if (this.$parent.context.payload.nextHighlightedProduct) {
        this.nextHighlightedProduct =
          this.$parent.context.payload.nextHighlightedProduct
      }

      this.loadingFooter = false
    } else {
      const footerDocument = await this.$prismic.client.getSingle('footer', {
        lang: this.$i18n.locale,
        graphQuery: footerGraphql,
      })
      this.footer = footerDocument.data
      this.loadingFooter = false
    }

    if (
      this.$parent.context &&
      this.$parent.context.payload &&
      this.$parent.context.payload.banner
    ) {
      this.banner = this.$parent.context.payload.banner.data
    } else {
      this.getBanner()
    }
  },
  head() {
    const lang = this.$i18n.locale.substring(0, 2)
    const head = {
      htmlAttrs: {
        lang,
      },
    }
    if (
      process.env.MOBILE_APP_BANNER &&
      process.env.MOBILE_APP_BANNER.includes('ios')
    ) {
      /** PROMPT NATIF IOS */
      head.meta = [
        {
          name: 'apple-itunes-app',
          content: 'app-id=1663813570',
        },
      ]
    }

    return head
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      customer: 'auth/customer',
      authenticated: 'auth/authenticated',
      connectedEmail: 'subscriptions/connectedEmail',
    }),
    bodyClass() {
      const cc = [`body--${this.$i18n.locale.substr(0, 2)}`]

      cc.push(`body--${process.env.SITE_VERSION}`)

      if (this.mounted) {
        cc.push('body__inner--mounted')
      }

      return cc
    },
    showBanner() {
      return this.banner && this.banner.visible
    },
  },
  watch: {
    $route(to, from) {
      if (process.client) {
        this.checkAuthentication(to)

        if (to.path.includes('/account') && to.path !== '/account/login') {
          this.initGorgias()
          this.$nuxt.$emit('gorgias:show')
        } else {
          this.$nuxt.$emit('gorgias:hide')
        }

        if (
          to.params.context &&
          from.params.context &&
          to.params.context !== from.params.context
        ) {
          this.$nextTick(() => {
            this.getBanner()
          })
        }
      }
    },
  },
  beforeDestroy() {
    this.$nuxt.$off(EVENTS.SHOW_FORCE_CONNECT)
    this.$nuxt.$off(EVENTS.CLOSE_CART)
    this.$nuxt.$off(EVENTS.ASK_NOTIFICATION)
    this.$nuxt.$off('gorgias:show')
    this.$nuxt.$off('gorgias:hide')
    window.removeEventListener('beforeinstallprompt')
    window.removeEventListener('gorgias-widget-loaded')
  },
  mounted() {
    this.loadRemoteNetlifyBuildId()

    this.initBatch()

    const pp = []

    pp.push(this.checkAuthentication())
    pp.push(this.loadCart())
    pp.push(this.loadBisSubscriptions())

    Promise.all(pp)
      .then((_) => {
        this.$nuxt.$emit(EVENTS.USER_STATE_LOADED)
      })
      .catch((err) => {
        this.$logError.captureException(err)
      })

    this.$nuxt.$on(EVENTS.SHOW_FORCE_CONNECT, (args) => {
      this.forceConnectEmail = args.email
      this.forceConnectStatus = args.status
      this.forceConnect = true
    })

    this.$nuxt.$on(EVENTS.CLOSE_CART, (_) => {
      this.toggleCart(null, true)
    })

    this.$nuxt.$on(EVENTS.USER_LOGGED_OUT, (_) => {
      this.$track.logout()
    })

    if (process.env.BUILD_ENV === 'production') {
      this.initWisepop()
    }

    if (
      this.$route.path.includes('/account') &&
      this.$route.path !== '/account/login'
    ) {
      this.initGorgias()
    }

    if (
      process.env.MOBILE_APP_BANNER &&
      process.env.MOBILE_APP_BANNER.includes('android')
    ) {
      this.loadPromptNativeApp()
    }

    this.$track.setLocale(this.$i18n.locale)
    this.$track.context(this.$route.params.context)
    this.$track.event('site_version', {
      version: process.env.SITE_VERSION,
    })

    if (window.optimize && window.optimize.variant) {
      this.$track.optimizeVariant(window.optimize.variant)
    }

    this.mounted = true
  },
  methods: {
    ...mapActions({
      getCheckout: 'cart/getCheckout',
      updateCartItemDelivery: 'cart/updateCartItemDelivery',
      addCartAttribute: 'cart/addAttribute',
      updateUpsell: 'cart/updateUpsell',
      updateUpsellItems: 'cart/updateUpsellItems',
      hasConnectedEmail: 'subscriptions/hasConnectedEmail',
      getUserSubscriptions: 'subscriptions/getUserSubscriptions',
      initAuthentication: 'auth/initAuthentication',
    }),

    loadRemoteNetlifyBuildId() {
      if (
        process.env.NODE_ENV === 'production' &&
        process.env.BUILD_ENV === 'production'
      ) {
        this.$axios
          .get(`${process.env.BASE_URL}/.netlify/functions/getBuildId`)
          .then((getBuildIdFromFunction) => {
            if (getBuildIdFromFunction?.data?.buildId) {
              localStorage.setItem(
                'netlifyBuildID',
                getBuildIdFromFunction.data.buildId
              )
            } else {
              localStorage.removeItem('netlifyBuildID')
            }
          })
          .catch((err) => {
            localStorage.removeItem('netlifyBuildID')
            this.$logError.captureException(err)
          })
      }
    },

    loadPromptNativeApp() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        this.proposeApp = true
      })
    },

    async loadCart() {
      const checkoutID = localStorage.getItem(LS_CHECKOUT_ID)

      if (checkoutID) {
        await this.getCheckout(checkoutID)

        if (this.cart.items.length > 0) {
          this.refreshProductsDelivery()
        }
      }
    },
    async loadBisSubscriptions() {
      await this.hasConnectedEmail().then((exists) => {
        if (exists) {
          return this.getUserSubscriptions(this.connectedEmail)
        }
      })
    },
    async checkAuthentication(to) {
      if (this.authenticated === null) {
        try {
          await this.initAuthentication()
        } catch (err) {
          this.$logError.captureException(err)
        }
      }

      if (this.authenticated) {
        if (!to) {
          this.$logError.identify(this.customer)
          this.$track.setUser(this.customer)
        }
      } else if (
        this.$route.path.includes('/account') ||
        to?.path.includes('/account')
      ) {
        if (
          this.$route.path.includes('/account/login') ||
          this.$route.path.includes('reset-password')
        ) {
          return
        }

        return this.$router.push(
          this.$contextPath(
            '/account/login?return_url=' +
              encodeURI(to ? to.path : this.$route.path)
          )
        )
      }
    },

    async getBanner() {
      try {
        const bannerDocument = await this.$prismic.client.getByUID(
          'banniere',
          `banner-${this.$route.params.context}`,
          {
            lang: this.$i18n.locale,
          }
        )

        this.banner = bannerDocument.data
      } catch (err) {
        if (!err.message.includes('No documents were returned')) {
          this.$logError.captureException(err)
        }
      }
    },

    getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      )
    },
    handleTouchStart(evt) {
      const touch = this.getTouches(evt)[0]
      this.touch.down.x = touch.clientX
      this.touch.down.y = touch.clientY

      document.addEventListener('touchmove', this.handleTouchMove)
    },
    handleTouchMove(evt) {
      const touch = this.getTouches(evt)[0]
      this.touch.move.x = touch.clientX
      this.touch.move.y = touch.clientY
    },
    handleTouchEnd(ev) {
      document.removeEventListener('touchmove', this.handleTouchMove)

      const xDiff = this.touch.move.x - this.touch.down.x
      const yDiff = this.touch.move.y - this.touch.down.y

      const hasSwipeLeft = xDiff > 50 && Math.abs(yDiff) < 50

      if (hasSwipeLeft) {
        if (this.isNavOpen) {
          this.toggleMenu(ev)
        }

        if (this.isCartOpen) {
          this.toggleCart(ev)
        }
      }
    },
    clickBody(ev) {
      if (this.isNavOpen) {
        const nav = document.getElementById('navMenu')

        if (!nav.contains(ev.target)) {
          this.toggleMenu(ev)
        }
      }

      if (this.isCartOpen) {
        const cart = document.getElementById('cartMenu')

        if (cart && !cart.contains(ev.target)) {
          this.toggleCart(ev)
        }
      }
    },

    openMenu(type, ev) {
      if (type === 'cart') {
        return this.toggleCart(ev)
      }

      return this.toggleMenu(ev)
    },

    async toggleMenu(ev) {
      ev && ev.stopPropagation()

      const main = document.getElementById('main')

      if (this.isNavOpen) {
        main.classList.remove('menu-open')
        document.body.style.overflow = ''
        document.documentElement.style.overflow = ''
        document.body.removeEventListener('click', this.clickBody)
        document.removeEventListener('touchstart', this.handleTouchStart)
        document.removeEventListener('touchend', this.handleTouchEnd)
        this.isNavOpen = false
      } else {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.overflow = 'hidden'

        document.body.addEventListener('click', this.clickBody)
        document.addEventListener('touchstart', this.handleTouchStart)
        document.addEventListener('touchend', this.handleTouchEnd)

        const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

        const menu = document.getElementById('navMenu')
        const children = menu.getElementsByClassName('nav-mobile__to-animate')

        for (const child of children) {
          child.classList.add('no-animate')
        }

        main.classList.add('menu-open')
        menu.classList.add('no-animate')

        const tl = gsap.timeline({
          onComplete: () => {
            this.isNavOpen = true
            menu.style = ''
            menu.classList.remove('no-animate')

            for (const child of children) {
              child.classList.remove('no-animate')
            }
          },
        })

        tl.to(menu, {
          right: 0,
          duration: 0.2,
          ease: 'ease.in',
        })

        tl.from(children, {
          opacity: 0,
          y: -20,
          duration: 0.3,
          stagger: {
            amount: 0.3,
            ease: 'power1.out',
          },
          ease: 'back.out(1.7)',
        })

        this.$track.event('menu-open')
      }

      return false
    },
    toggleCart(ev, close = null) {
      ev && ev.stopPropagation()

      const main = document.getElementById('main')

      if (this.isCartOpen || (close !== null && close)) {
        main.classList.remove('cart-open')
        document.body.style.overflow = ''
        document.documentElement.style.overflow = ''
        document.body.removeEventListener('click', this.clickBody)
        document.removeEventListener('touchstart', this.handleTouchStart)
        document.removeEventListener('touchend', this.handleTouchEnd)
        this.isCartOpen = false
      } else {
        main.classList.add('cart-open')
        document.body.style.overflow = 'hidden'
        document.documentElement.style.overflow = 'hidden'

        document.body.addEventListener('click', this.clickBody)
        document.addEventListener('touchstart', this.handleTouchStart)
        document.addEventListener('touchend', this.handleTouchEnd)

        this.updateUpsellItems()

        if (!this.cart.hasAttribute('site_version', process.env.SITE_VERSION)) {
          this.addCartAttribute({
            key: 'site_version',
            value: process.env.SITE_VERSION,
          }).catch((err) => {
            this.$logError.captureException(err)
          })
        }

        if (this.cart.items.length > 0) {
          this.refreshProductsDelivery()
          this.$refs.cartMenu.updateUpsellingBlock()
        }

        this.isCartOpen = true
        this.$track.event('cart-open')
      }

      return false
    },

    async refreshProductsDelivery() {
      const productsWithMetafieldDelivery =
        await services.productService.getShopifyProductsMetafieldDelivery(
          [...new Set(this.cart.items.map((i) => i.productId))],
          this.$i18n.locale
        )

      this.updateCartItemDelivery({
        products: productsWithMetafieldDelivery,
        getDeliveryText: (d) => {
          const deliveryText = this.getDeliveryFromDate(d)
          return this.$t(deliveryText.ref, deliveryText.options)
        },
      })
    },

    switchContext(context) {
      const locale = this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })
      const wispIds = locale.wisp

      if (window.wisp && window.wisp.updateWebsiteId) {
        window.wisp.updateWebsiteId(wispIds[context])
      }
    },

    initWisepop() {
      const locale = this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })
      const wisepopIds = locale.wisepop
      const c = this.$route.params.context

      if (wisepopIds[c]) {
        const wisepopUrl = `https://wisepops.net/loader.js?v=2&h=${wisepopIds[c]}`
        const a = document.createElement('script')
        a.src = wisepopUrl
        a.defer = true
        a.async = true
        document.body.appendChild(a)
      }
    },

    initGorgias() {
      if (document.getElementById('gorgias-chat-widget-install')) {
        return false
      }
      const locale = this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })
      const c = this.$route.params.context
      const gorgiasIDs = locale.gorgias

      if (!gorgiasIDs[c]) {
        return false
      }

      window.addEventListener('gorgias-widget-loaded', () => {
        this.gorgiasIsReady = true

        this.$nuxt.$on('gorgias:show', () => {
          if (this.gorgiasIsReady && window.GorgiasChat) {
            const chatElem = document.getElementById('gorgias-chat-container')
            chatElem.style.display = ''
          }
        })

        this.$nuxt.$on('gorgias:hide', () => {
          if (this.gorgiasIsReady && window.GorgiasChat) {
            const chatElem = document.getElementById('gorgias-chat-container')
            chatElem.style.display = 'none'
          }
        })
      })

      const gorgiasID = gorgiasIDs[c]

      const t = document.getElementsByTagName('script')[0]
      const s = document.createElement('script')
      s.async = true
      s.id = 'gorgias-chat-widget-install'
      s.src = `https://config.gorgias.chat/bundle-loader/${gorgiasID}`
      s.type = 'text/javascript'
      t.parentNode.insertBefore(s, t)
    },

    initBatch() {
      /* Load remote Batch SDK JavaScript code */
      ;(function (b, a, t, c, h, e, r) {
        h = 'batchSDK'
        b[h] =
          b[h] ||
          function () {
            ;(b[h].q = b[h].q || []).push(arguments)
          }
        e = a.createElement(t)
        r = a.getElementsByTagName(t)[0]
        e.async = 1
        e.src = c
        r.parentNode.insertBefore(e, r)
      })(
        window,
        document,
        'script',
        'https://via.batch.com/v3/bootstrap.min.js'
      )

      /* Initiate Batch SDK opt-in UI configuration (native prompt) */
      let batchSDKUIConfig = {
        native: {},
      }

      /* Use a specific configuration for Firefox and Safari browsers (custom prompt) */
      if (
        navigator.userAgent.includes('Firefox') ||
        navigator.userAgent.includes('Safari') ||
        navigator.userAgent.includes('Chrome')
      ) {
        batchSDKUIConfig = {
          native: {
            autoShow: false,
          },
          alert: {
            autoShow: false,
            text: this.$t('notification.text'),
            positiveSubBtnLabel: this.$t('notification.positive'),
            negativeBtnLabel: this.$t('notification.negative'),
            backgroundColor: '#f6f3f0',
            textColor: '#002e5d',
            fontSize: 16,
            fontFamily: 'var(--primary-font-family)',
            positiveBtnStyle: {
              backgroundColor: '#002e5d',
              textColor: '#f6f3f0',
              hoverBackgroundColor: '#ffc73c',
            },
            negativeBtnStyle: {
              textColor: '#002e5d',
            },
          },
        }
      }

      /* Finalize the Batch SDK setup */
      batchSDK('setup', {
        apiKey: process.env.BATCH_API_KEY,
        subdomain: process.env.BATCH_SUBDOMAIN,
        authKey: process.env.BATCH_AUTH_KEY,
        dev: process.env.BUILD_ENV !== 'production',
        vapidPublicKey: process.env.BATCH_VAPID_KEY,
        ui: batchSDKUIConfig,
        safari: {
          'https://www.asphalte.com': 'web.asphalte.com',
        },
      })

      batchSDK((api) => {
        api.on('subscriptionChanged', (_, s) => {
          if (s.subscribed === true) {
            api.editUserData((editor) => {
              editor.setAttribute('subscription_source', this.$route.path)
            })

            this.$track.event('notification_granted')
          } else {
            this.$track.event('notification_denied')
          }
        })

        api.getUserAttributes().then((attributes) => {
          const firstPageSeen = attributes.first_page_seen

          if (!firstPageSeen) {
            api.editUserData((editor) => {
              editor.setAttribute('first_page_seen', this.$route.path)
            })
          }

          if (
            typeof Notification !== 'undefined' &&
            Notification.permission === 'granted'
          ) {
            const subscriptionSource = attributes.subscription_source
            if (
              subscriptionSource === undefined &&
              localStorage.getItem('pushowl_subscriber_token') !== null
            ) {
              api.editUserData((editor) => {
                editor.setAttribute(
                  'subscription_source',
                  this.$i18n.locale !== process.env.DEFAULT_LOCALE
                    ? `${process.env.BASE_URL}/${this.$i18n.locale}/h`
                    : `${process.env.BASE_URL}/h`
                )
                editor.setAttribute('from_pushowl', true)
              })
            }
          }

          if (process.env.BUILD_ENV !== 'production') {
            api.editUserData((editor) => {
              editor.setAttribute('dev', true)
            })
          }
        })
        // .catch((err) => {
        // this.$logError.captureException(err)
        // })
      })

      setTimeout((_) => {
        batchSDK((api) => {
          if (!navigator.userAgent.includes('Safari')) {
            api.ui.show('native')
          }
        })
      }, 10000)

      this.$nuxt.$on(EVENTS.ASK_NOTIFICATION, () => {
        if (
          typeof Notification !== 'undefined' &&
          Notification.permission !== 'granted' &&
          Notification.permission !== 'denied' &&
          !this.notificationAsked
        ) {
          batchSDK((api) => {
            api.ui.show('alert', true)
          })
          this.notificationAsked = true
        }
      })
    },
  },
}
</script>

<style lang="scss">
.body__inner {
  position: relative;
}

#main {
  transition: all 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 2001;
    transition: all 0.3s;
  }

  &.menu-open,
  &.cart-open {
    &::before {
      background: rgba($primary-color, 0.5);
      pointer-events: all;
    }
  }
}

.side-menu {
  position: fixed;
  max-width: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  z-index: 2005;
  transition: all 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);
}
</style>
