var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.skeleton)?_c('nav',{staticClass:"header__nav"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"header__nav__link skeleton",staticStyle:{"width":"120px","margin":"0 5px"}})}),0):_c('nav',{ref:"nav",staticClass:"header__nav"},[_c('div',{key:`nav-${_vm.renderTime}`,staticClass:"d-flex h-100"},[(_vm.eshopMenuVersion.length > 0)?_c('div',{ref:"eshopLinks",staticClass:"d-flex p-relative"},_vm._l((_vm.eshopMenuVersion),function(link){return _c(_vm.linkComponent(link),_vm._b({key:link.text,tag:"component",staticClass:"header__nav__link link--underline text-secondary text-uppercase",class:{
          'header__nav__link--highlight': link.highlight,
          'header__nav__link--submenu': _vm.hasSubmenu(link),
        },style:([
          link.highlight_color
            ? { '--link-highlight-color': link.highlight_color }
            : {},
          link.highlight_background
            ? { '--link-highlight-background': link.highlight_background }
            : {},
        ]),on:{"pointerenter":function($event){return _vm.onLinkHoverIn($event, link)},"pointerleave":function($event){return _vm.onLinkHoverOut(link)}}},'component',_vm.linkAttributes(link),false),[(link.text)?_c('span',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(link.text)+"\n          "),(link.countdown)?_c('b',{staticClass:"link__countdown pl-0-4"},[_vm._v(_vm._s(_vm.getCountdown(link)))]):_vm._e()]):_vm._e()])}),1):_vm._e(),_vm._v(" "),_vm._l((_vm.menuVersion),function(link){return _c(_vm.linkComponent(link),_vm._b({key:link.text,tag:"component",staticClass:"header__nav__link link--underline text-secondary text-uppercase",class:{ 'header__nav__link--highlight': link.highlight },style:([
        link.highlight_color
          ? { '--link-highlight-color': link.highlight_color }
          : {},
        link.highlight_background
          ? { '--link-highlight-background': link.highlight_background }
          : {},
      ]),on:{"pointerenter":function($event){return _vm.onLinkHoverIn($event, link)},"pointerleave":function($event){return _vm.onLinkHoverOut(link)}}},'component',_vm.linkAttributes(link),false),[(link.text)?_c('span',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(link.text)+"\n        "),(link.countdown)?_c('b',{staticClass:"link__countdown pl-0-4"},[_vm._v(_vm._s(_vm.getCountdown(link)))]):_vm._e()]):_vm._e()])})],2),_vm._v(" "),_vm._l((_vm.subMenus),function(subMenu){return _c('div',{key:subMenu.uid,staticClass:"header__nav__submenu",attrs:{"id":`sub_menu_${subMenu.uid}`},on:{"pointerenter":function($event){return _vm.onSubMenuIn(subMenu)},"pointerleave":function($event){return _vm.onSubMenuOut(subMenu)}}},_vm._l((subMenu.data.links),function(link){return _c('nuxt-link',{key:link.text,staticClass:"header__nav__link link--underline text-secondary text-uppercase",class:{ 'header__nav__link--highlight': link.highlight },style:([
        link.highlight_color
          ? { '--link-highlight-color': link.highlight_color }
          : {},
        link.highlight_background
          ? { '--link-highlight-background': link.highlight_background }
          : {},
      ]),attrs:{"to":_vm.$contextPrismicPath(link.link),"prefetch":_vm.$isAPrefetchLink(link.link)}},[(link.text)?_c('span',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(link.text)+"\n        "),(link.countdown)?_c('b',{staticClass:"link__countdown pl-0-4"},[_vm._v(_vm._s(_vm.getCountdown(link)))]):_vm._e()]):_vm._e()])}),1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }