<template>
  <nav v-if="skeleton" class="header__nav">
    <div
      v-for="i in 3"
      :key="i"
      class="header__nav__link skeleton"
      style="width: 120px; margin: 0 5px"
    ></div>
  </nav>
  <nav v-else ref="nav" class="header__nav">
    <div :key="`nav-${renderTime}`" class="d-flex h-100">
      <div
        v-if="eshopMenuVersion.length > 0"
        ref="eshopLinks"
        class="d-flex p-relative"
      >
        <component
          :is="linkComponent(link)"
          v-for="link in eshopMenuVersion"
          :key="link.text"
          class="
            header__nav__link
            link--underline
            text-secondary text-uppercase
          "
          :class="{
            'header__nav__link--highlight': link.highlight,
            'header__nav__link--submenu': hasSubmenu(link),
          }"
          :style="[
            link.highlight_color
              ? { '--link-highlight-color': link.highlight_color }
              : {},
            link.highlight_background
              ? { '--link-highlight-background': link.highlight_background }
              : {},
          ]"
          v-bind="linkAttributes(link)"
          @pointerenter="onLinkHoverIn($event, link)"
          @pointerleave="onLinkHoverOut(link)"
        >
          <span v-if="link.text" class="d-flex align-center"
            >{{ link.text }}
            <b v-if="link.countdown" class="link__countdown pl-0-4">{{
              getCountdown(link)
            }}</b></span
          >
        </component>
      </div>
      <component
        :is="linkComponent(link)"
        v-for="link in menuVersion"
        :key="link.text"
        class="header__nav__link link--underline text-secondary text-uppercase"
        :class="{ 'header__nav__link--highlight': link.highlight }"
        :style="[
          link.highlight_color
            ? { '--link-highlight-color': link.highlight_color }
            : {},
          link.highlight_background
            ? { '--link-highlight-background': link.highlight_background }
            : {},
        ]"
        v-bind="linkAttributes(link)"
        @pointerenter="onLinkHoverIn($event, link)"
        @pointerleave="onLinkHoverOut(link)"
      >
        <span v-if="link.text" class="d-flex align-center"
          >{{ link.text }}
          <b v-if="link.countdown" class="link__countdown pl-0-4">{{
            getCountdown(link)
          }}</b></span
        >
      </component>
    </div>

    <div
      v-for="subMenu in subMenus"
      :id="`sub_menu_${subMenu.uid}`"
      :key="subMenu.uid"
      class="header__nav__submenu"
      @pointerenter="onSubMenuIn(subMenu)"
      @pointerleave="onSubMenuOut(subMenu)"
    >
      <nuxt-link
        v-for="link in subMenu.data.links"
        :key="link.text"
        class="header__nav__link link--underline text-secondary text-uppercase"
        :class="{ 'header__nav__link--highlight': link.highlight }"
        :style="[
          link.highlight_color
            ? { '--link-highlight-color': link.highlight_color }
            : {},
          link.highlight_background
            ? { '--link-highlight-background': link.highlight_background }
            : {},
        ]"
        :to="$contextPrismicPath(link.link)"
        :prefetch="$isAPrefetchLink(link.link)"
      >
        <span v-if="link.text" class="d-flex align-center"
          >{{ link.text }}
          <b v-if="link.countdown" class="link__countdown pl-0-4">{{
            getCountdown(link)
          }}</b></span
        >
      </nuxt-link>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import * as prismicH from '@prismicio/helpers'
import { differenceInDays, differenceInHours, isAfter } from 'date-fns'

import Icon from '@/components/icons/Icon'

export default {
  name: 'NavHeader',
  components: {
    Icon,
  },
  props: {
    eshopMenu: {
      type: Array,
      default: () => [],
    },
    menu: {
      type: Array,
      default: () => [],
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    renderTime: 0,
    timeouts: {},
    subMenuOpened: [],
    subMenuHoverIn: [],
  }),

  computed: {
    eshopMenuVersion() {
      return this.eshopMenu.filter(
        (link) =>
          link.ab_test_version === 'all' ||
          link.ab_test_version === process.env.SITE_VERSION
      )
    },
    menuVersion() {
      return this.menu.filter(
        (link) =>
          link.ab_test_version === 'all' ||
          link.ab_test_version === process.env.SITE_VERSION
      )
    },
    subMenus() {
      const subMenus = []

      for (const link of this.eshopMenu) {
        if (prismicH.isFilled.contentRelationship(link.sub_menu)) {
          subMenus.push(link.sub_menu)
        }
      }

      for (const link of this.menu) {
        if (prismicH.isFilled.contentRelationship(link.sub_menu)) {
          subMenus.push(link.sub_menu)
        }
      }

      return subMenus
    },
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.forceUpdate)
  },
  mounted() {
    document.addEventListener('visibilitychange', this.forceUpdate)

    this.renderTime = new Date().getTime()
  },
  methods: {
    ...mapMutations({
      addObjectToClose: 'behavior/ADD_OBJECT_TO_CLOSE',
      removeObjectToClose: 'behavior/CLEAN_OBJECT_TO_CLOSE',
    }),
    ...mapActions({
      closeOtherMenus: 'behavior/closeObjects',
    }),
    hasSubmenu(link) {
      return prismicH.isFilled.contentRelationship(link.sub_menu)
    },
    linkComponent(link) {
      return this.hasSubmenu(link)
        ? 'div'
        : prismicH.isFilled.contentRelationship(link.link)
        ? 'nuxt-link'
        : 'a'
    },
    linkAttributes(link) {
      if (this.hasSubmenu(link)) {
        return {}
      }

      if (prismicH.isFilled.contentRelationship(link.link)) {
        return {
          to: this.$contextPrismicPath(link.link),
          prefetch: this.$isAPrefetchLink(link.link),
        }
      }

      return {}
    },

    onLinkHoverIn(ev, link) {
      if (this.hasSubmenu(link)) {
        const subMenuEl = document.getElementById(
          `sub_menu_${link.sub_menu.uid}`
        )

        if (subMenuEl && ev.target) {
          for (const uid of this.subMenuOpened) {
            if (uid !== link.sub_menu.uid) {
              this.subMenuClose(uid)
            }
          }

          clearTimeout(this.timeouts[link.sub_menu.uid])
          this.subMenuOpen(subMenuEl, ev.target.getBoundingClientRect())
          this.subMenuOpened.push(link.sub_menu.uid)
        }
      }
    },

    onLinkHoverOut(link) {
      if (this.hasSubmenu(link)) {
        clearTimeout(this.timeouts[link.sub_menu.uid])
        this.timeouts[link.sub_menu.uid] = setTimeout(() => {
          if (!this.subMenuHoverIn.includes(link.sub_menu.uid)) {
            this.subMenuClose(link.sub_menu.uid)
          }
        }, 500)
      }
    },

    onSubMenuIn(subMenu) {
      this.subMenuHoverIn.push(subMenu.uid)
    },

    onSubMenuOut(subMenu) {
      this.subMenuHoverIn = this.subMenuHoverIn.filter(
        (uid) => uid !== subMenu.uid
      )

      clearTimeout(this.timeouts[subMenu.uid])
      this.timeouts[subMenu.uid] = setTimeout(() => {
        if (!this.subMenuHoverIn.includes(subMenu.uid)) {
          this.subMenuClose(subMenu.uid)
        }
      }, 300)
    },

    async subMenuOpen(el, rect) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      let height = 0

      for (const child of el.children) {
        height += child.offsetHeight
      }

      const left = rect.left + rect.width / 2 - el.offsetWidth / 2

      gsap.set(el, {
        top: `${rect.bottom + 1}px`,
        left: `${left}px`,
      })

      gsap.to(el, {
        height,
        duration: 0.3,
        ease: 'power2.out',
      })
    },

    async subMenuClose(uid) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)
      const el = document.getElementById(`sub_menu_${uid}`)

      if (el) {
        gsap.to(el, {
          height: 0,
          duration: 0.3,
          ease: 'power2.out',
          onComplete: () => {
            this.subMenuOpened = this.subMenuOpened.filter((u) => u !== uid)
          },
        })
      }
    },

    forceUpdate() {
      if (!document.hidden) {
        this.renderTime = new Date().getTime()
      }
    },

    getCountdown(link) {
      if (link.countdown) {
        const date = new Date(link.countdown)
        const now = new Date()

        if (isAfter(date, now)) {
          const diffInDays = differenceInDays(date, now)

          if (diffInDays > 1) {
            return this.$t('countdown.abbr.days', {
              days: diffInDays,
            })
          }

          const diffInHours = differenceInHours(date, now)
          if (diffInHours >= 1) {
            return this.$t('countdown.abbr.hours', {
              hours: diffInHours,
            })
          }
        }
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
.header__nav {
  display: flex;
  margin: 0 auto;

  @include mq($until: 1024px) {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: calc(var(--spacing) * 0.4);
    padding: 0 calc(var(--spacing) * 0.6) calc(var(--spacing) * 1.4);
    letter-spacing: 0.08em;
    line-height: 1;
    white-space: nowrap;
    height: 100%;

    @include mq($from: 1400px) {
      padding: 0 var(--spacing) calc(var(--spacing) * 1.4);
    }

    &--highlight {
      --link-highlight-background: #{rgba($yellow, 0.9)} default;
      --link-highlight-color: var(--secondary-color) default;

      span {
        color: var(--link-highlight-color);
        background: var(--link-highlight-background);
        padding: rem(2px) rem(3px);
        margin-bottom: rem(-2px);
      }
    }

    &--overflow {
      display: none;
    }

    &.nuxt-link-exact-active,
    &.header__nav__link--active {
      --link-underline-transform: scaleX(1);
      --link-underline-origin: 0% 50%;
    }

    &__now {
      position: absolute;
      bottom: calc(var(--spacing) * 3);
      left: var(--spacing);
      font-size: var(--label-font-size);
      font-family: var(--primary-font-family);
      font-style: normal;
      font-weight: 500;
      color: var(--secondary-color-1);
    }
  }

  &__eshop {
    position: absolute;
    display: flex;
    align-items: center;
    inset: calc(var(--spacing) * 0.8) var(--spacing) auto;
    opacity: 0;
    pointer-events: none;
    transition: all 0.7s var(--asphalte-animation-function);

    .line {
      flex: 1;
      height: 1px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        background: var(--secondary-color-3);
        height: 1px;
        transition: width 0.5s var(--asphalte-animation-function);
      }

      &:first-child::before {
        right: 0;
      }

      &:last-child::before {
        left: 0;
      }
    }

    p {
      color: var(--sun-color);
    }
  }

  &__submenu {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--tertiary-color);
    z-index: 1;
    overflow: hidden;
    height: 0;

    .header__nav__link {
      padding: var(--spacing);
      background: var(--tertiary-color);
      justify-content: center;
      border: solid var(--secondary-color-4);
      border-width: 0 rem(1px) rem(1px);
      color: var(--secondary-color);
      transition: none;
      min-width: rem(200px);
      height: auto;
    }
  }

  &__other {
    position: relative;

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    .button__inner {
      display: flex;
      align-items: center;

      .icon {
        margin-left: calc(var(--spacing) * 0.4);
      }
    }
  }

  &--context {
    margin-left: calc(var(--spacing) * 0.6);
  }
}

.header {
  &:hover {
    .header__nav__eshop {
      opacity: 1;
      transition: all 0.3s var(--asphalte-animation-function) 0s;

      .line {
        &::before {
          width: 100%;
          transition: width 0.7s var(--asphalte-animation-function) 0.2s;
        }
      }
    }
  }
}
</style>
