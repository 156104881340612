const footerGraphql = `{
    footer {
      newsletter_title
      newsletter_text
      reinsurance {
        label
        link
      }
      pre_footer_links_h {
        to {
          ... on collection {
            image
            image_cdn
            image_footer_cdn
          }
        }
        title
        link_text
        link_type
      }
      left_links_h {
        label
        link
      }
      right_links_h {
        label
        link
      }
      social_networks_h {
        link
        type
      }
      b_corp_link_h
      pre_footer_links_f {
        to {
          ... on collection {
            image
            image_cdn
            image_footer_cdn
          }
        }
        title
        link_text
        link_type
      }
      left_links_f {
        label
        link
      }
      right_links_f {
        label
        link
      }
      social_networks_f {
        link
        type
      }
      b_corp_link_f
    }
  }`
export default footerGraphql
