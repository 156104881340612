<template>
  <div
    class="cart-item"
    :class="{ 'cart-item--loading': loading }"
    data-cy-cart-menu-item
  >
    <div class="row row-no-wrap">
      <div class="cart-item__image">
        <img :src="item.imageUrl" alt="" />
      </div>
      <div class="cart-item__info">
        <p class="w-600 mb-0">
          <nuxt-link
            v-if="isLink"
            :to="resolve({ type: 'product', uid })"
            class="cart-item__link"
            @click.native="$nuxt.$emit(EVENTS.CLOSE_CART)"
          >
            {{ item.productTitle }}
            <span v-if="hasGroup">- {{ group }}</span>
          </nuxt-link>
          <span v-else>
            {{ item.productTitle }}
          </span>
        </p>
        <p class="text-small mb-0-6">{{ item.variantTitle }}</p>

        <div class="d-flex justify-space-between align-center">
          <form-input-number
            :id="`${item.variantId}-quantity`"
            :label="$t('label.quantity')"
            :value="item.quantity"
            data-cy-cart-item-input-number
            @input="updateQuantity"
          />

          <div class="cart-item__price h6 text-secondary w-700 d-md-none">
            {{ $n(item.totalPrice, 'currency') }}
          </div>
        </div>
      </div>
      <div class="cart-item__price h6 text-secondary w-700 d-none d-md-block">
        {{ $n(item.totalPrice, 'currency') }}
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from '@/entities/cart/CartItem'
import FormInputNumber from '@/components/form/InputNumber'

import { EVENTS } from '@/const/events'

export default {
  name: 'CartItem',
  components: {
    FormInputNumber,
  },
  props: {
    item: {
      type: CartItem,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ EVENTS }),
  computed: {
    isLink() {
      return (
        this.item.productId !== process.env.SUIT_HOUSE_ID ||
        this.$i18n.locale !== toLocale
      )
    },
    hasGroup() {
      return (
        this.item.selectedOptions.length > 0 &&
        this.item.selectedOptions.some((s) => s.name === 'Group')
      )
    },
    group() {
      const g = this.item.selectedOptions.find((s) => s.name === 'Group')

      if (g) {
        return g.value
      }

      return ''
    },
    uid() {
      const slug = this.item.customAttributes.find((a) => a.key === '_slug')
      if (slug) {
        return slug.value
      }

      return item.productHandle
    },
    toLocale() {
      const locale = this.item.customAttributes.find((a) => a.key === '_locale')
      if (locale) {
        return locale.value
      }

      return this.$i18n.locale
    },
  },
  methods: {
    resolve(l) {
      return this.$contextPrismicPath(l)
    },
    updateQuantity(q) {
      this.$emit('update-quantity', this.item, parseInt(q))
    },
  },
}
</script>

<style lang="scss">
.cart-item {
  padding-top: rem($spacing);

  @include mq($from: tablet) {
    padding-top: rem($spacing * 1.5);
  }

  &__image {
    padding-right: rem($spacing);

    img {
      width: rem(86px);
    }
  }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;

    @include on-hover-and-focus {
      text-decoration: underline;
    }

    .moving & {
      pointer-events: none;
    }
  }

  &__price {
    align-self: flex-end;

    @include mq($from: tablet) {
      align-self: center;
    }
  }

  .input--number {
    margin-bottom: 0;

    label {
      @include visually-hidden;
    }
  }

  &--loading {
    .input--number {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
