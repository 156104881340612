var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.upsell.has && _vm.itemsAreAvailableInLocale)?_c('div',{staticClass:"cart-menu__upselling"},[_c('div',{staticClass:"cart-menu__upselling__top d-flex justify-space-between"},[_c('p',{staticClass:"text-label text-md-small text-uppercase text-md-none w-600 mb-0"},[_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.cart.items.length === 0
            ? 'cart.upsell.title.empty'
            : _vm.upsell.group && _vm.upsell.group.type === _vm.UPSELLING_TYPES.PRODUCTS
            ? `cart.upsell.title.${_vm.groupName}`
            : 'cart.upsell.title.link'
        ))+"\n    ")]),_vm._v(" "),(_vm.upsell.list.length > 1 && _vm.cart.items.length > 0)?_c('nav',{staticClass:"cart-menu__upselling__nav d-flex"},[_c('primary-button',{attrs:{"text":_vm.$t('prev'),"text-visually-hidden":"","type":"button","aspect":"transparent","with-arrow":"","arrow-position":"left","arrow-direction":"left"},on:{"click":_vm.prev}}),_vm._v(" "),_c('span',{staticClass:"text-label"},[_vm._v("\n        "+_vm._s(_vm.currentUpsellingItem)+" / "+_vm._s(_vm.upsell.list.length)+"\n      ")]),_vm._v(" "),_c('primary-button',{attrs:{"text":_vm.$t('next'),"text-visually-hidden":"","type":"button","aspect":"transparent","with-arrow":"","arrow-direction":"right"},on:{"click":_vm.next}})],1):_vm._e()]),_vm._v(" "),_c('div',{ref:"upsellItems",staticClass:"cart-menu__upselling__elements ml-1 ml-md-1-5 mr-md-1-5 mb-0-8",class:{ 'only-one': _vm.upsell.list.length === 1 },on:{"mousedown":_vm.onMouseDown,"touchstart":_vm.onMouseDown}},_vm._l((_vm.upsell.list),function(item,index){return _c('cart-up-sell-item',{key:item.id,ref:"upsellItem",refInFor:true,attrs:{"id":`upsellItem${index}`,"item":item,"loading":_vm.isLoadingUpselling},nativeOn:{"click":function($event){index + 1 !== _vm.currentUpsellingItem ? _vm.goTo(index + 1) : ''}}})}),1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }