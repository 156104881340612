<template>
  <div class="lang-switcher" :class="{ open: isOpen }">
    <div class="lang-switcher__link">
      <a ref="link" href="#" @click.prevent="toggle">
        <span>
          {{ $t(`locale.small.${$i18n.locale}`) }}
          <icon name="carret-down" class="arrow" />
        </span>
      </a>
    </div>

    <transition @beforeEnter="beforeEnter" @enter="navOpen" @leave="navClose">
      <nav v-if="isOpen" class="lang-switcher__nav">
        <div v-for="locale in availableLocales" :key="locale.iso">
          <a
            :href="getUrl(locale.iso)"
            class="link--underline"
            @click="$track.click('switch_lang', { locale: locale.code })"
          >
            <span>{{ $t(`locale.${locale.iso}`) }}</span>
          </a>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

import Icon from '@/components/icons/Icon'

export default {
  name: 'I18nSwitcher',
  components: {
    Icon,
  },
  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((l) => l.iso !== this.$i18n.locale)
    },
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickBody)
  },
  methods: {
    ...mapMutations({
      addObjectToClose: 'behavior/ADD_OBJECT_TO_CLOSE',
      removeObjectToClose: 'behavior/CLEAN_OBJECT_TO_CLOSE',
    }),
    ...mapActions({
      closeOtherMenus: 'behavior/closeObjects',
    }),
    getUrl(localeIso) {
      if (localeIso === process.env.DEFAULT_LOCALE) {
        return process.env.BASE_URL
      }
      return `${process.env.BASE_URL}/${localeIso}`
    },

    toggle(ev) {
      ev.stopPropagation()

      this.$refs.link.blur()
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.closeOtherMenus()
        this.$track.click('lang_switcher_open')
        document.body.addEventListener('click', this.clickBody)
        this.addObjectToClose(() => {
          this.close()
        })
      } else {
        document.body.removeEventListener('click', this.clickBody)
        this.removeObjectToClose()
      }
    },
    close() {
      this.isOpen = false
      document.body.removeEventListener('click', this.clickBody)
      this.removeObjectToClose()
    },
    beforeEnter(el) {
      el.style = 'opacity: 0'
    },
    async navOpen(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      gsap.to(el, { opacity: 1, duration: 0 })
      gsap.from(el.children, {
        y: -20,
        opacity: 0,
        duration: 0.2,
        stagger: 0.1,
        onComplete() {
          done()
        },
      })
    },
    async navClose(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      gsap.to(el.children, {
        y: -20,
        opacity: 0,
        duration: 0.2,
        stagger: {
          amount: 0.1,
          from: 'end',
        },
        onComplete() {
          done()
        },
      })
    },
    clickBody(ev) {
      if (!this.$el.contains(ev.target)) {
        this.close()
      }
    },
  },
}
</script>

<style lang="scss">
.lang-switcher {
  position: relative;
  font-family: var(--secondary-font-family);

  &__link {
    a {
      display: block;
    }

    span {
      position: relative;
    }

    .arrow {
      position: absolute;
      top: 50%;
      left: rem(-18px);
      transition: all 0.3s;
      transform: translateY(-50%);
      transform-origin: 50% 50%;
    }
  }

  &__nav {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.open {
    .lang-switcher__link {
      .arrow {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }
}
</style>
