<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
  >
    <path
      d="M16 7.29853L15.1753 4.8956L9.23711 7.00549V0.5H6.59794V6.88828L0.76976 5.24725L0 7.7674L5.82818 9.52564L2.41924 14.9176L4.50859 16.5L7.86254 11.2253L11.6014 16.5L13.6357 14.9176L9.95189 9.58425L16 7.29853Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconStar',
}
</script>
