<template>
  <div @mouseover="hovered = true" @mouseleave="hovered = false">
    <nuxt-link
      v-if="link"
      :to="$contextPrismicPath(link.to)"
      class="prefooter-links"
    >
      <lazy-image
        :image="link.image"
        :image-cdn="link.imageCdn"
        :widths="[720, 1440, 2160]"
      />

      <div class="prefooter-links__content">
        <h2 class="mb-0">{{ title }}</h2>
        <icon name="arrow-right" />
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import * as prismicH from '@prismicio/helpers'
import LazyImage from '@/components/Image/LazyImage'
import Icon from '@/components/icons/Icon'

export default {
  name: 'PreFooterLink',
  components: {
    LazyImage,
    Icon,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hovered: false,
  }),
  computed: {
    title() {
      if (prismicH.isFilled.title(this.link.title)) {
        return prismicH.asText(this.link.title)
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
.prefooter-links {
  position: relative;
  display: block;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      0deg,
      RGB(0 11 21 / 0.5) 0%,
      RGB(0 11 21 / 0) 40.62%
    );
    z-index: 1;
  }

  img {
    transition: transform 0.3s var(--asphalte-animation-function);
  }

  &__content {
    position: absolute;
    inset: auto var(--spacing) calc(var(--spacing) * 1.6);
    z-index: 2;
    display: flex;
    align-items: center;
    color: var(--tertiary-color);

    @include mq($from: tablet) {
      inset: auto calc(var(--spacing) * 2) calc(var(--spacing) * 2);
    }

    h2 {
      color: var(--tertiary-color);
      margin-bottom: 0;
    }

    .icon {
      width: rem(21px);
      height: rem(21px);
      margin-left: calc(var(--spacing) * 1.4);
      transition: all 0.5s var(--asphalte-animation-function);
      opacity: 0;
      transform: translateX(30px);
    }
  }

  @include on-hover-and-focus {
    img {
      transform: scale(1.1);
    }

    .icon {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
</style>
