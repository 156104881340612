<template>
  <div class="input input--number">
    <label :for="id" class="d-block mb-0-6">
      {{ label }}
    </label>
    <div class="input__container">
      <button
        class="input__modifier input__modifier--decrease"
        data-cy-input-number-decrease
        @click="decrease"
      >
        <span class="visually-hidden">-1</span>
      </button>
      <input
        :id="id"
        ref="input"
        type="number"
        :value="value"
        class="h6"
        data-cy-input-number-input
        @input="input($event)"
      />
      <button
        class="input__modifier input__modifier--increase"
        data-cy-input-number-increase
        @click="increase"
      >
        <span class="visually-hidden">+1</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInputNumber',
  props: {
    /**
     * Contenu du champs texte
     */
    value: { type: Number, required: true },

    /**
     * L'id du champs qui sert à lié le champs et son label
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Le label associé au champs
     */
    label: {
      type: String,
      required: true,
    },
  },
  methods: {
    input(ev) {
      /**
       * Update input value
       *
       * @event input
       */
      this.$emit('input', ev.target.value)
    },
    decrease(ev) {
      this.$emit('input', this.value - 1)
      ev.target.blur()
    },
    increase(ev) {
      this.$emit('input', this.value + 1)
      ev.target.blur()
    },
  },
}
</script>

<style lang="scss">
.input--number {
  position: relative;

  input {
    font-family: var(--secondary-font-family);
    background: transparent;
    width: 100%;
    height: rem(24px);
    padding: 0 rem($spacing * 2);
    border: 0;
    text-align: center;
    font-weight: 700;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  .input__container {
    width: rem(80px);
    border: 0;
  }

  .input__modifier {
    background: transparent;
    position: absolute;
    top: 50%;
    font-size: rem(24px);
    transform: translateY(-50%);
    border-radius: 50%;
    color: var(--secondary-color-3);
    border: 1px solid currentcolor;
    padding: 0;
    line-height: 1;
    width: rem(24px);
    height: rem(24px);
    transition: all 0.3s;
    cursor: pointer;

    @include on-hover-and-focus {
      color: var(--secondary-color);
    }

    &--decrease {
      left: 0;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(2px);
        background: currentcolor;
        transform: translate(-50%, -50%);
      }
    }

    &--increase {
      right: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(2px);
        background: currentcolor;
      }

      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }
}
</style>
