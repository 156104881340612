const DeliveryMixin = {
  methods: {
    format(number) {
      if (this.$i18n.locale === 'en-US') {
        return number === '1' || number === '21' || number === '31'
          ? number + 'st'
          : number + 'th'
      }
      return number
    },
    getDeliveryFromDate(d, prefix = null) {
      if (d.getDate() === 24 && d.getMonth() === 11) {
        // (January gives 0, December gives 11)
        return { ref: 'purchase.beforeChristmas', options: {} }
      }

      let first = '11'
      let last = '20'
      if (d.getDate() === 1) {
        first = '1'
        last = '10'
      } else if (d.getDate() === 10) {
        first = '1'
        last = '15'
      } else if (d.getDate() === 20) {
        first = '16'
        last = `${new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()}`
      } else if (d.getDate() >= 28) {
        first = '21'
        last = `${d.getDate()}`
      }
      if (prefix) {
        return {
          ref: 'purchase.between',
          options: {
            [`${prefix}_first`]: this.format(first),
            [`${prefix}_last`]: this.format(last),
            [`${prefix}_month`]: this.$d(d, 'long-month'),
          },
        }
      }
      return {
        ref: 'purchase.between',
        options: {
          first: this.format(first),
          last: this.format(last),
          month: this.$d(d, 'long-month'),
        },
      }
    },
  },
}

export default DeliveryMixin
