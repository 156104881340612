<template>
  <nuxt-link
    v-if="link.link_type === 'Document'"
    :to="getLink()"
    :href="getLink()"
    ><slot
  /></nuxt-link>
  <a v-else :href="link.url" :target="link.target"><slot /></a>
</template>

<script>
export default {
  name: 'PrismicLink',
  props: {
    link: { type: Object, required: true },
  },
  methods: {
    getLink() {
      return this.$contextPrismicPath(this.link)
    },
  },
}
</script>
